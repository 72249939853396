<template>
    <div>
        <h1 class="azul-seth text-uppercase text-center mb-5">
            <div style="width: 250px; max-width: 80vw" class="m-auto">
                <img style="width: 100%" :src="directorio.logo_url">
            </div>
        </h1>

        <FotoCuadrada
                class="border-radius-15 mb-4"
                factor="1.8"
                :src="directorio.main_photo_url"/>

        <div class="row">
            <div class="col-lg-3 col-6 mb-3"
                 v-for="h in directorio.home"
                 :key="h.id">

                <ButtonContent
                        v-if="h.type === 'contenido'"
                        :home="h" />

                <ButtonLink
                        v-if="h.type === 'url'"
                        :home="h" />

                <ButtonPiscinas
                        v-if="h.type === 'hojas_registro'"
                        :home="h" />
            </div>
        </div>
    </div>
</template>

<script>

    import FotoCuadrada from "@/components/FotoCuadrada";
    import ButtonContent from "@/partials/BotonesContenido/ButtonContent";
    import ButtonPiscinas from "@/partials/BotonesContenido/ButtonPiscinas";
    import ButtonLink from "@/partials/BotonesContenido/ButtonLink";

    export default {

        name: 'HomeView',

        components: {ButtonLink, ButtonPiscinas, ButtonContent, FotoCuadrada},

        data() {
            return {

            };
        },

        computed: {
            directorio() {
                return this.$store.getters.directorio;
            }
        },

        created() {}

    }
</script>

