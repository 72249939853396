<template>
    <div class="grey lighten-3 p-3 border-radius-5 caja azul-seth">

        <div v-if="booking === null">
            <div class="mb-3">
                <label>ID Reserva / Voucher</label>
                <input type="text" placeholder="ID Reserva" v-model="info.id">
            </div>

            <div>
                <label>DNI/ID</label>
                <input type="text" v-model="info.nif">
            </div>

            <div class="mt-3 cursor-pointer font-12" @click="info.politica = !info.politica">
                <IconCheck v-if="!info.politica" stroke="1.5" size="22"/>
                <IconCheckActive v-else stroke="1.5" size="22"/>

                Acepto
                <a href="https://sethotels.com/es/politica-de-privacidad" target="_blank" class="text-decoration-none azul-seth ms-1 cursor-pointer font-600">Politica de privacidad</a>
            </div>

            <hr/>

            <button :disabled="!info.politica" @click="saveInfo" class="btn btn-sm white font-600 azul-seth">
                <IconSave stroke="2" size="17"/> Guardar
            </button>
        </div>

        <div v-else class="azul-seth">
            <div class="mb-2 d-flex">
                <div class="w-100 cut-text">
                    <strong>{{booking.guest.first_name}} {{booking.guest.last_name}}</strong>
                </div>
                <div class="font-600" style="width: 100px">
                    <span class="float-end">
                        <IconRoom size=16 stroke=2 /> {{booking.room.room_title}}
                    </span>
                </div>
            </div>

            <div>
                <div class="w-100 white border-radius-5 overflow-hidden mb-4">
                    <div style="height: 7px"
                         v-bind:style="{'width': `${calculateProgress(booking.room.start_date, booking.room.end_date)}%`}"
                         class="blue-grey lighten-3"></div>
                </div>

                <div class="mb-2">
                    <div class="mb-2 d-flex" v-for="e in booking.extras" :key="e.extra_id">
                        <div class="w-100 cut-text">
                            - {{e.extra_title}}
                        </div>
                        <div class="font-600" style="width: 100px">
                            <span class="float-end">{{e.amount}}€</span>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="mb-2 d-flex">
                        <div class="w-100 cut-text">
                            Total:
                        </div>
                        <div class="font-600" style="width: 100px">
                            <span class="float-end">{{total(booking.extras)}}€</span>
                        </div>
                    </div>
                </div>

                <hr/>

                <button @click="clearInfo" class="btn btn-sm white azul-seth font-600">
                    <IconLogout stroke="2" size="20" class="cursor-pointer me-1"/> Salir
                </button>
            </div>

        </div>
    </div>
</template>

<script>

    import IconLogout from "@/partials/Icons/IconLogout";
    import IconSave from "@/partials/Icons/IconSave";
    import IconCheck from "@/partials/Icons/IconCheck";
    import IconCheckActive from "@/partials/Icons/IconCheckActive";
    import IconRoom from "@/partials/Icons/IconRoom";

    export default {
        components: {IconRoom, IconCheckActive, IconCheck, IconSave, IconLogout},
        props: [],

        name: 'BookingComponent',

        data() {
            return  {
                info: {
                    id: null,
                    nif: null,
                    politica: false,
                }
            }
        },

        computed: {
            booking() {
                return this.$store.getters.booking;
            },
        },

        methods: {
            saveInfo(){
                this.$store.dispatch('AUTH_SET_INFO', this.info);
            },
            clearInfo(){
                this.$store.dispatch('AUTH_CLEAR_INFO', this.info);
            },
            calculateProgress(startDate, endDate) {
                // Parse the input dates
                const start = new Date(startDate);
                const end = new Date(endDate + ' 12:00:00');

                // Calculate the current date
                const currentDate = new Date();

                // Calculate the total time span between start and end
                const totalTime = end - start;

                // Calculate the time elapsed from start to current date
                const timeElapsed = currentDate - start;

                // Calculate the progress in percentage
                const progress = (timeElapsed / totalTime) * 100;

                // Make sure progress is within the 0-100% range
                return Math.min(Math.max(progress, 0), 100);
            },
            total(extras){
                let total = 0;

                extras.forEach(el => {
                    total = total + el.amount;
                });

                return total;
            }
        },

        mounted() {},

        created() {
            this.info = this.$store.getters.info;
        },

    };
</script>

<style scoped>
    .caja{
        font-size: 14px;
        line-height: 14px;
    }

    label{
        margin-bottom: 5px;
        font-weight: 500;
    }
</style>