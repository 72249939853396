<template>
    <div>
        <FotoCuadrada factor="1.5"
                      :src="home.photo"
                      class="border-radius-15 overflow-hidden cursor-pointer">

            <a :href="home.dest"
               target="_blank"
               class="w-100 h-100 d-flex justify-content-center align-items-center white-text text-decoration-none link text-center p-3">
                {{home.button_text}}
            </a>

        </FotoCuadrada>
    </div>
</template>

<script>

    import FotoCuadrada from "@/components/FotoCuadrada";

    export default {
        props: ['home'],

        name: 'ButtonLink',

        components: {FotoCuadrada},

        methods: {},

        computed: {},

        mounted() {
        }
    }

</script>
<style scoped>
    .link {
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 18px;
        transition: ease 200ms;
    }

    .link:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
</style>
