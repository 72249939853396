<template>
    <BaseIcon :size="size" :stroke="stroke" :rotate="rotate">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"></path>
        <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
        <path d="M14 4l0 4l-6 0l0 -4"></path>
    </BaseIcon>
</template>

<script>
    import BaseIcon from "./BaseIcon";

    export default {
        components: {BaseIcon},
        props: ['size', 'stroke', 'rotate'],
        name: 'IconSave',
    }
</script>