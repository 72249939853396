<template>
    <div style="height: 50px">
        <nav id="topNav"
             class="p-3 clearfix position-fixed w-100"
             style="top: 0px">
            <div class="float-start">
                <div class="d-flex">
                    <IconMenu @click="toggleNavigation"
                              class="cursor-pointer azul-seth me-3"
                              style="margin-top: 2px"
                              size="24"
                              stroke="2"/>

                    <router-link to="/" class="btn btn-sm white azul-seth">
                        <IconHome stroke="2" size="18"/>
                    </router-link>
                </div>
            </div>

            <div class="float-end">
                <div class="d-flex">
                    <button v-for="(idioma, index) in directorio.idiomas"
                            :key="index"
                            @click="setLocale(idioma)"
                            :style="{'background-image': `url(${directorio.banderas[idioma]})`}"
                            class="me-1 cursor-pointer background-image-placement button-idioma me-2"></button>
                </div>
            </div>
        </nav>

        <!-- Side Nav -->
        <nav class="white" id="sideNav">
            <div class="clearfix p-3">
                <IconClose
                        @click="toggleNavigation"
                        class="cursor-pointer azul-seth float-end"
                        stroke="2"
                        size="22"/>
            </div>

            <!-- Logo -->
            <div class="p-3 mb-3">
                <div class="" style="padding: 0px 15px">
                    <router-link to="/">
                        <img style="width: 100%" :src="directorio.logo_url">
                    </router-link>
                </div>
            </div>

            <!-- Menu -->
            <div class="overflow-auto ps-3 pe-3"
                 style="height: calc(100dvh - 215px)">
                <div class="clearfix position-relative">
                    <div class="mb-3">
                        <router-link
                                class="content-link azul-seth"
                                v-for="c in directorio.contenido"
                                :key="c.id"
                                :to="`/contenido/${c.id}`">
                            <div>
                                {{c.titulo}}
                            </div>
                        </router-link>
                    </div>

                    <div>
                        <BookingComponent />
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>

    import IconMenu from "@/partials/Icons/IconMenu";
    import {toggleNav} from "@/functions";
    import IconClose from "@/partials/Icons/IconClose";
    import IconHome from "@/partials/Icons/IconHome";
    import BookingComponent from "@/components/BookingComponent";

    export default {
        name: 'NavBar',

        components: {BookingComponent, IconHome, IconClose, IconMenu},

        methods: {
            setLocale(locale) {
                localStorage.locale = locale;
                location.reload();
            },
            toggleNavigation() {
                toggleNav();
            }
        },

        computed: {
            directorio() {
                return this.$store.getters.directorio
            }
        }
    }

</script>

<style scoped>
    .button-idioma {
        height: 26px;
        width: 30px;
        border: none !important;
        border-radius: 15px;
        margin-bottom: 0px;
    }

    #sideNav {
        position: fixed;
        height: 100vh;
        width: 300px;
        border-radius: 0px 20px 20px 0px;
        z-index: 999;
        left: -310px;
        top: 0px;
        transition: 0.5s;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }

    #topNav {
        width: 100%;
        backdrop-filter: blur(15px);
        background-color: rgba(240, 240, 240, 0.5)
    }

    .content-link {
        line-height: normal;
        font-size: 16px !important;
        text-decoration: none !important;
        font-weight: 400;
    }

    .content-link div {
        width: 100%;
        text-decoration: none !important;
        padding: 5px 20px;
        border-radius: 15px;
    }

    .content-link:hover div {
        background-color: #f5f5f5;
        font-weight: 500;
    }

    .content-link:active {
        color: #003457
    }

    .router-link-exact-active div {
        background-color: #f5f5f5;
        font-weight: 500;
    }


</style>