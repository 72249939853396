<template>
    <div
            :id="uniqueId"
            class="background-image-placement"
            v-bind:style="{'background-image': `url(${src})`}">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'FotoCuadrada',

        props: ['src', 'factor'],

        computed: {
            uniqueId() {
                // Generate a unique ID for the element using a combination of timestamp and a random number
                return `element_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
            },
        },

        mounted() {
            window.addEventListener('resize', () => {
                const photo = document.getElementById(this.uniqueId);
                photo.style.height = `${photo.clientWidth / this.factor}px`;
            });

            const photo = document.getElementById(this.uniqueId);
            photo.style.height = `${photo.clientWidth / this.factor}px`;
        },
    };
</script>