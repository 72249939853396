import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './scss/app.scss'
import {getI18n} from "@/locales/locales";
import axios from 'axios'
import store from "@/store";

const app = createApp(App).use(router).use(store);

app.config.globalProperties.axios = axios
app.config.globalProperties.i18n = getI18n();

app.mount('#app');