import Vuex from "vuex";
import directorio from "@/store/directorio";
import booking from "@/store/booking";

const store = new Vuex.Store({
  modules: {
    directorio: directorio,
    booking: booking
  },
});

export default store;