<template>
    <BaseIcon :size="size" :stroke="stroke" :rotate="rotate">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
            <path d="M9 12l2 2l4 -4"></path>
    </BaseIcon>
</template>

<script>
    import BaseIcon from "./BaseIcon";

    export default {
        components: {BaseIcon},
        props: ['size', 'stroke', 'rotate'],
        name: 'IconCheck',
    }
</script>
