import axios from "axios";
import ENV from "@/defines";

const headers = ENV.HEADERS;

const ROUTES = {
    get: ENV.API_URL + '/api/directorios/booking',
};

export default {
    state: {
        booking: null,
        id: localStorage.book_id || null,
        nif: localStorage.book_nif || null,
    },
    mutations: {
        SET_BOOKING(state, content) {
            state.booking = content;
        },
        SET_INFO(state, content) {
            state.id = content.id;
            state.nif = content.nif;
        },
        CLEAR_INFO(state) {
            state.booking = null;
            state.id = null;
            state.nif = null;
        }
    },
    actions: {
        async AUTH_GET_BOOKING({commit, state}) {
            if (state.id !== null && state.id !== 'null') {
                try {
                    const resp = await axios.post(ROUTES.get, {
                        url: window.location.host,
                        booking: state.id,
                        nif: state.nif,
                    }, {headers: headers});

                    if (resp.data !== '' && resp.data !== null) {
                        commit('SET_BOOKING', resp.data);
                    }
                } catch (error) {
                    console.error('Error fetching content:', error);
                }
            }
        },
        AUTH_SET_INFO({commit, dispatch}, content) {
            localStorage.book_id = content.id;
            localStorage.book_nif = content.nif;
            commit('SET_INFO', content);
            dispatch('AUTH_GET_BOOKING');
        },
        AUTH_CLEAR_INFO({commit}) {
            localStorage.removeItem("book_id");
            localStorage.removeItem("book_nif");
            commit('CLEAR_INFO');
        }
    },
    getters: {
        booking: (state) => state.booking,
        info: (state) => {
            return {id: state.id, nif: state.nif}
        },
    },
};
