<template>
    <div>

        <ModalComponent ref="modalHoja">
            <template #button>
                <FotoCuadrada
                        @click="loadContent"
                        factor="1.5"
                        :src="home.photo"
                        class="border-radius-15 overflow-hidden cursor-pointer">
                    <div class="w-100 h-100 d-flex justify-content-center align-items-center white-text text-decoration-none link text-center p-3">
                        {{home.button_text}}
                    </div>
                </FotoCuadrada>
            </template>

            <div style="margin-bottom: -15px">
                <div v-for="(hoja, index) in hojas"
                     v-html="hoja.data"
                     :key="index"
                     class="hoja azul-seth p-3 grey lighten-4 border-radius-5 mb-3"/>
            </div>
        </ModalComponent>
    </div>
</template>

<script>

    import FotoCuadrada from "@/components/FotoCuadrada";
    import ModalComponent from "@/components/ModalComponent";
    import HojaRegistro from "@/Models/hojaRegistro";

    export default {
        props: ['home'],

        name: 'ButtonPiscinas',

        data() {
            return {
                hojas: [],
                loading: true,
            };
        },

        components: {ModalComponent, FotoCuadrada},

        methods: {
            loadContent() {
                const hoja = new HojaRegistro(this.home.dest)
                hoja.get().then(resp => {
                    this.hojas = resp;
                });
            }
        },

        computed: {
            referencia() {
                return 'modalHojas=' + this.home.id;
            }
        },

        mounted() {
        }
    }

</script>

<style scoped>
    .link {
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 18px;
        transition: ease 200ms;
    }

    .link:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }

    .hoja {
        font-size: 12px;
    }
</style>
