<template>

  <div>
    <LoadingView v-if="loading"/>

    <div v-else>
      <NavBar/>
      <div class="container pt-5 pb-5" style="min-height: 100vh">
        <router-view/>
      </div>

      <NavFooter/>
    </div>
  </div>

</template>

<style lang="scss" rel="stylesheet/scss"></style>

<script>

import NavBar from "@/partials/NavBar";
import LoadingView from "@/partials/LoadingView";
import NavFooter from "@/partials/NavFooter";

export default {
  name: 'App',

  components: {NavFooter, LoadingView, NavBar},

  data() {
    return {
      loading: true
    };
  },

  created() {
    this.$store.dispatch('fetchContent').then(() => {
      this.loading = false;

      const google_analytics_id = this.$store.getters.directorio.google_analytics_id;

      if (google_analytics_id !== null) {
        fetch('https://api.sethotels.net/api/analytics/' + google_analytics_id, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({url: window.location.href, referrer_url: document.referrer, title: document.title})
        });
      }
    });

    this.$store.dispatch('AUTH_GET_BOOKING');

  }
}

</script>

