import Directorio from "@/Models/directorio";

export default {
  state: {
    directorio: null,
  },
  mutations: {
    setDirectorio(state, content) {
      state.directorio = content;
    },
  },
  actions: {
    async fetchContent({ commit }) {
      try {
        const directorio = new Directorio(localStorage.locale);
        const resp = await directorio.getHome(window.location.host);
        document.title = `Directorio - ${resp.data.nombre}`;
        commit('setDirectorio', resp.data);
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    },
  },
  getters: {
    directorio: (state) => state.directorio,
  },
};
