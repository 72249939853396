<template>
    <div id="loadingScreen" class="align-items-center justify-content-center d-flex">
        <IconLoader2
                size="50"
                stroke="2"
                class="icon-rotate blue-grey-text text-darken-4"/>
    </div>
</template>

<script>

    import IconLoader2 from "@/partials/Icons/IconLoader2";

    export default {
        name: 'LoadingView',
        components: {IconLoader2},
        methods: {},
    }

</script>

<style scoped>
    #loadingScreen {
        position: absolute;
        height: 100vh;
        width: 100vw;
        background-color: white;
    }
</style>