<template>
    <footer class="p-3 grey lighten-4 text-center azul-seth font-12">
        Powered by
        <a href="https://sethotels.com" target="_blank" class="text-decoration-none azul-seth font-700">Set Hotels</a>
    </footer>
</template>

<script>

    export default {
        name: 'NavFooter',
    }

</script>
