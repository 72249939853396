import axios from 'axios';

export default class HojaRegistro {
    constructor(links) {
        this.links = links.split(',');
    }

    async get() {
        try {
            const responses = await Promise.all(this.links.map(el => axios.get(el)));
            return responses;
        } catch (error) {
            throw error;
        }
    }
}
