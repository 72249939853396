import ENV from "@/defines";
import axios from 'axios'

const headers = ENV.HEADERS;
const ROUTES = {
    getHome: ENV.API_URL + '/api/directorio/get',
};

export default class Directorio {
    constructor(locale = 'es') {
        this.locale = locale;
    }

    async getHome(url){
        try {
            return await axios.post(ROUTES.getHome, {url: url, locale: this.locale}, {headers: headers});
        } catch (error) {
            throw error;
        }
    }
}