<template>

    <span class="tabler-icon">
          <svg xmlns="http://www.w3.org/2000/svg"
               class="icon icon-tabler icon-tabler-menu-2"
               :class="{'icon-rotate': rotate !== undefined}"
               :width="size"
               :height="size"
               :stroke-width="stroke"
               viewBox="0 0 24 24"
               stroke="currentColor"
               fill="none"
               stroke-linecap="round"
               stroke-linejoin="round">
            <slot></slot>
          </svg>
    </span>

</template>

<script>
    export default {
        props: ['size', 'stroke', 'rotate'],
        name: 'BaseIcon',
    }
</script>

<style scoped>

    svg{
        margin-top: -2px !important;
    }

</style>