<template>
    <div @click="visible = !visible">
        <slot name="button"/>
    </div>

    <div class="position-fixed vue-modal-back p-3"
         v-bind:class="{'active': visible}"
         style="top: 0px; left: 0px; height: 100vh; width: 100vw">

        <div v-bind:class="{'active': visible}" class="vue-modal position-relative shadow">
            <div class="clearfix p-3">
                <IconClose
                        class="cursor-pointer float-end azul-seth"
                        size="24"
                        stroke="2"
                        @click="visible = !visible"/>
            </div>
            <div class="overflow-auto ps-3 pe-3" style="height: 80vh">
                <slot/>
            </div>
            <div class="pb-3"></div>
        </div>
        
    </div>
</template>

<script>
    import IconClose from "@/partials/Icons/IconClose";

    export default {
        components: {IconClose},

        props: [],

        name: 'ModalComponent',

        data() {
            return {
                visible: null
            }
        }
    };
</script>

<style scoped>

    .vue-modal-back {
        background-color: rgba(0, 0, 0, 0.0);
        visibility: hidden;
        transition: ease 300ms;
    }

    .vue-modal-back.active {
        background-color: rgba(0, 0, 0, 0.5);
        visibility: visible;
    }

    .vue-modal {
        z-index: 999;
        top: -100vh;
        width: 550px;
        max-width: 100%;
        border: none !important;
        visibility: hidden;
        margin: auto;
        background-color: white;
        transition: ease 400ms;
    }

    .vue-modal.active {
        top: 40px;
        visibility: visible;
        border-radius: 10px;
    }

</style>